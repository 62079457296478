import {
  getRequest,
  putRequest,
  postRequest,
  delRequest,
  Response,
} from '~utils';
import {
  AddToCalendarResponse,
  AnswerQuestionResponse,
  ArtistEventsResponse,
  AskQuestionResponse,
  BlockFanResponse,
  EndAllCurrentEventResponse,
  EventActionResponse,
  EventQuestionsResponse,
  JoinEventResponse,
  VoteQuestionResponse,
  EventAttendeesResponse,
  EditEventParameter,
  ReportView,
  Event,
  RecordInfo,
  ShowAttendeeStatsResponse,
} from '~types/event';
import { LandingPageEvent } from '~types/landing-page';
import { ReportTab } from '~components/manage-profile/tabs/reports/type';

export const getUserEvents = async (
  artistId: string,
  limit?: number,
): Promise<ArtistEventsResponse> => {
  return await getRequest({
    path: 'events/by-artist-id',
    config: {
      params: limit
        ? {
            id: artistId,
            limit,
          }
        : {
            id: artistId,
          },
    },
  });
};

export const addEventToCalendar = async (
  eventId: string,
): Promise<Response<AddToCalendarResponse>> => {
  return await putRequest({
    path: 'events/add-to-calendar',
    data: {
      event_id: eventId,
    },
  });
};

export const blockFan = async (
  fanId: string,
  eventId: string,
): Promise<Response<BlockFanResponse>> => {
  return await putRequest({
    path: 'events/block-fan',
    data: {
      fan_id: fanId,
      event_id: eventId,
    },
  });
};

export const startEvent = async (
  eventId: string,
  session: string,
  isNotified: boolean,
  recordInfo?: RecordInfo,
): Promise<Response<EventActionResponse>> => {
  return await putRequest({
    path: 'events/start',
    data: {
      event_id: eventId,
      session: session,
      is_notified: isNotified,
      record_info: recordInfo,
    },
  });
};

export const endEvent = async (
  eventId: string,
): Promise<Response<EventActionResponse>> => {
  return await putRequest({
    path: 'events/end',
    data: {
      event_id: eventId,
    },
  });
};

export const voteQuestion = async (
  questionId: string,
  isUp: boolean,
): Promise<Response<VoteQuestionResponse>> => {
  return await putRequest({
    path: 'events/vote-question',
    data: {
      question_id: questionId,
      up: isUp,
    },
  });
};

export const answerQuestion = async (
  questionId: string,
  isAnswered: boolean,
): Promise<Response<AnswerQuestionResponse>> => {
  return await putRequest({
    path: 'events/answer-question',
    data: {
      question_id: questionId,
      answered: isAnswered,
    },
  });
};

export const askQuestion = async (
  title: string,
  eventId: string,
  artistId: string,
  chatGroupId?: string,
): Promise<Response<AskQuestionResponse>> => {
  return await postRequest({
    path: 'events/ask-question',
    data: {
      title,
      event_id: eventId,
      artist_id: artistId,
      chatGroupId: chatGroupId,
    },
  });
};

export const joinEvent = async (
  eventId: string,
  session: string,
  clientId: string,
  recordingByAgora?: boolean,
): Promise<Response<JoinEventResponse>> => {
  return await putRequest({
    path: 'events/join',
    data: {
      eventId,
      session,
      clientId,
      recordingByAgora,
    },
  });
};

export const addNewEvent = async (
  scheduledAt?: string,
  thumbnail?: string,
): Promise<Response<EventActionResponse>> => {
  return await postRequest({
    path: 'events/create',
    data: {
      scheduled_at: scheduledAt,
      imageUrl: thumbnail,
    },
  });
};

export const saveEvents = async (
  newEvents: { scheduled_at: string }[],
  updatedEvents: EditEventParameter[],
): Promise<Response<EventActionResponse>> => {
  return await postRequest({
    path: 'events/create-and-update',
    data: {
      added_events: newEvents,
      updated_events: updatedEvents,
    },
  });
};

export const deleteEvent = async (
  eventId: string,
): Promise<Response<Event>> => {
  return await delRequest({
    path: `events/${eventId}`,
  });
};

export const getListQuestions = async (
  artistId: string,
): Promise<Response<EventQuestionsResponse>> => {
  return await getRequest({
    path: 'events/questions',
    config: {
      params: {
        artist_id: artistId,
      },
    },
  });
};

export const endAllCurrentEvent = async (): Promise<
  Response<EndAllCurrentEventResponse>
> => {
  return await putRequest({
    path: 'events/end-bulk',
  });
};

export const getEventAttendees = async (
  eventId: string,
): Promise<Response<EventAttendeesResponse>> => {
  return await getRequest({
    path: 'events/attendees',
    config: {
      params: {
        event_id: eventId,
      },
    },
  });
};

export const getOngoingEvents = async ({
  limit,
  offset,
  isPartner,
}: {
  limit: number;
  offset: number;
  isPartner?: boolean;
}): Promise<LandingPageEvent[]> => {
  return await getRequest({
    path: 'events/ongoing',
    config: {
      params: {
        limit,
        offset,
        isPartner,
      },
    },
  });
};
export const getUpcomingEvents = async ({
  limit,
  offset,
  isPartner,
}: {
  limit: number;
  offset: number;
  isPartner?: boolean;
}): Promise<LandingPageEvent[]> => {
  return await getRequest({
    path: 'events/upcoming',
    config: {
      params: {
        limit,
        offset,
        isPartner,
      },
    },
  });
};
export const getEndedEvents = async ({
  limit,
  offset,
  isPartner,
}: {
  limit: number;
  offset: number;
  isPartner?: boolean;
}): Promise<LandingPageEvent[]> => {
  return await getRequest({
    path: 'events/ended',
    config: {
      params: {
        is_featured: true,
        limit,
        offset,
        isPartner,
      },
    },
  });
};

export const getEventInfo = async (
  eventId: string,
): Promise<LandingPageEvent> => {
  return await getRequest({
    path: `events/feature/ended-by-id/${eventId}`,
  });
};

export const getReportsData = async (): Promise<ReportTab> => {
  return await getRequest({
    path: 'reports',
  });
};
export const getMoreReports = async ({
  limit,
  offset,
}: {
  limit: number;
  offset: number;
}): Promise<ReportView[]> => {
  return await getRequest({
    path: 'reports/page',
    config: {
      params: {
        limit,
        offset,
      },
    },
  });
};

export const trackingEvent = async (
  eventId: string,
): Promise<Response<Event>> => {
  return await putRequest({
    path: `events/${eventId}/tracking-available`,
  });
};

export const showAttendeeStats = async (
  eventId: string,
  showed: boolean,
): Promise<Response<ShowAttendeeStatsResponse>> => {
  return await putRequest({
    path: 'events/show-attendees-stat',
    data: {
      event_id: eventId,
      showed: showed,
    },
  });
};

export const updateLayoutCloudRecording = async (
  eventId: string,
  audienceUid: number,
  isOnStage: boolean,
): Promise<Response<boolean>> => {
  return await putRequest({
    path: 'events/update-recording-layout',
    data: {
      audienceUid: audienceUid,
      eventId: eventId,
      isOnStage: isOnStage,
    },
  });
};
