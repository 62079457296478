import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { showErrorToaster } from '~utils/toasterNotification';
import { isEmptyMessage, strictValidArrayWithLength } from '~utils/commonUtils';
import { FEATURE_FLAGS_TYPE, USER_TYPE } from '~utils/constants';
import { useGlobalDispatch, useGlobalState } from '~utils/container';
import { askQuestion, getListQuestions } from '~api/event';
import EmojiPicker, { EmojiClickData, Theme } from 'emoji-picker-react';
import {
  Box,
  IconButton,
  InputAdornment,
  List,
  Stack,
  TextField,
  Typography,
  alpha,
  styled,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { ScrollBar } from '~components/atoms';
import ArtistQuestionItem from './artist-question-item';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '~constants/query-keys';
import { FanQuestion } from '~types/event';
import { isEmpty } from 'lodash';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useFeatureFlag } from '~hooks/useFeatureFlag';
import {
  BrazeEventType,
  useBrazeEventPusher,
} from '~hooks/useBrazeEventPusher';

const StyledTextField = styled(TextField)(({ theme }) => ({
  position: 'absolute',
  bottom: 30,
  [theme.breakpoints.down('md')]: {
    '.MuiOutlinedInput-root': {
      backgroundColor: theme.palette.grey[700],
    },
  },
}));

const ArtistQuestion = () => {
  const theme = useTheme();
  const [question, setQuestion] = useState('');
  const questionElement = useRef<HTMLUListElement | null>(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const isLaptopOrTabletScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const { pushBrazeEvent } = useBrazeEventPusher();

  const {
    artist: { currentEvent, id: artistId },
    app: { questionsList, blockedUsername },
    user: { type, id: userId },
    config: { chatGroupId },
  }: // TODO need to define data type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Record<string, any> = useGlobalState();
  const dispatch = useGlobalDispatch();

  const isFan = type === USER_TYPE.FAN || (!type && !userId);

  const { isEnable } = useFeatureFlag();
  const isEnableSystemChat = isEnable(
    FEATURE_FLAGS_TYPE.SYSTEM_CHAT_ON_TIP_QUESTION_FOLLOW,
  );

  const questionListWithBlockedUser = useMemo(() => {
    if (type === USER_TYPE.ARTIST && !isEmpty(blockedUsername)) {
      return (questionsList as FanQuestion[]).filter(
        (record) => blockedUsername.indexOf(record.fan?.username) < 0,
      );
    }
    return questionsList;
  }, [blockedUsername, questionsList, type]);

  useQuery({
    queryKey: [QUERY_KEYS.ARTIST_QUESTIONS],
    queryFn: () => getListQuestions(artistId),
    refetchOnWindowFocus: false,
    onSuccess: (data: { questions: FanQuestion[] }) => {
      dispatch({ type: 'app', payload: { questionsList: data.questions } });
    },
  });

  const handleSubmit = async () => {
    setShowEmojiPicker(false);
    if (isEmptyMessage(question)) return;
    if (!type || !userId) {
      dispatch({
        type: 'app',
        payload: { popup: 'log-in' },
      });
      return;
    }

    const data = {
      event: currentEvent ? currentEvent : undefined,
      title: question,
      artist: artistId,
    };
    setQuestion('');
    try {
      const { data: question } = await askQuestion(
        data.title,
        data.event,
        data.artist,
        isEnableSystemChat ? chatGroupId : undefined,
      );

      pushBrazeEvent(BrazeEventType.USER_SEND_QUESTION_TO_ARTIST, {
        artistId: artistId,
      });

      if (question) {
        const updatedQuestions = [...questionsList, question];
        dispatch({ type: 'app', payload: { questionsList: updatedQuestions } });
        return true;
      }
    } catch (err) {
      showErrorToaster(err);
    }
  };

  const handleKeyDown = async (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === 'Enter' && !evt.shiftKey) {
      evt.preventDefault();
      const success = await handleSubmit();
      if (success) {
        setQuestion('');
      }
    }
  };

  const handleEmojiClick = (emojiObject: EmojiClickData) => {
    const cursorStart = inputRef?.current?.selectionStart;
    const cursorEnd = inputRef?.current?.selectionEnd;
    setQuestion(
      (message) =>
        message.slice(0, cursorStart ?? 0) +
        emojiObject.emoji +
        message.slice(cursorEnd ?? 0),
    );

    const newCursor = (cursorStart ?? 0) + emojiObject.emoji.length;
    setTimeout(() => {
      inputRef?.current?.setSelectionRange(newCursor, newCursor);
    }, 20);
  };

  //with smooth-scroll
  const scrollToBottomWithSmoothScroll = useCallback(() => {
    if (questionElement?.current) {
      setTimeout(() => {
        questionElement?.current?.scrollTo({
          top: questionElement.current.scrollHeight,
          behavior: 'smooth',
        });
      }, 500);
    }
  }, []);

  useEffect(() => {
    scrollToBottomWithSmoothScroll();
  }, [questionListWithBlockedUser, scrollToBottomWithSmoothScroll]);

  return (
    <Stack height={'100%'} position={'relative'}>
      <List
        dense
        ref={questionElement}
        component={ScrollBar}
        sx={{
          overflowX: 'hidden',
          overflowY: 'auto',
          height: 'calc(100% - 78px)',
        }}
      >
        {strictValidArrayWithLength(questionListWithBlockedUser) ? (
          questionListWithBlockedUser.map((item: FanQuestion) => (
            <ArtistQuestionItem key={item.question.id} item={item} />
          ))
        ) : (
          <Typography variant="subtitle2">
            {isFan
              ? 'Please submit a question for the artist below.'
              : 'There are no questions submitted yet. Please encourage your fans to submit a question!'}
          </Typography>
        )}
      </List>
      {isFan && (
        <Box>
          <Box sx={{ position: 'absolute', bottom: 80, right: 20 }}>
            <EmojiPicker
              onEmojiClick={handleEmojiClick}
              theme={Theme.DARK}
              lazyLoadEmojis={true}
              open={showEmojiPicker}
              previewConfig={{ showPreview: false }}
              width={250}
              height={300}
            />
          </Box>

          <StyledTextField
            inputRef={inputRef}
            fullWidth
            margin="dense"
            size="small"
            variant="outlined"
            autoComplete="false"
            placeholder="Send a question"
            onChange={(e) => setQuestion(e.target.value)}
            value={question}
            onKeyDown={handleKeyDown}
            onFocus={() => setShowEmojiPicker(false)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {isLaptopOrTabletScreen && (
                    <IconButton
                      size="small"
                      onClick={() =>
                        setShowEmojiPicker(
                          (showEmojiPicker) => !showEmojiPicker,
                        )
                      }
                    >
                      <InsertEmoticonIcon
                        sx={{
                          width: (theme) => theme.spacing(2.5),
                          height: (theme) => theme.spacing(2.5),
                          color: (theme) =>
                            alpha(theme.palette.text.primary, 0.3),
                        }}
                      />
                    </IconButton>
                  )}

                  <IconButton size="small" onClick={handleSubmit}>
                    <SendIcon
                      sx={{
                        width: (theme) => theme.spacing(2.5),
                        height: (theme) => theme.spacing(2.5),
                        color: (theme) =>
                          alpha(theme.palette.text.primary, 0.3),
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}
    </Stack>
  );
};

export default ArtistQuestion;
