import * as React from 'react';
import { ChangeEvent, useContext, useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  styled,
  Typography,
} from '@mui/material';
import classNames from 'classnames/bind';
import popupStyles from '~components/popup/popup.module.scss';
import {
  getUserInfo,
  resendActivationEmail,
  UserStatus,
  verifyAccount,
  checkEmailExist,
  checkUsernameExist,
} from '~api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  AgoraVideoContext,
  isTwoStringSame,
  POPUP_TYPE,
  useGlobalDispatch,
  useGlobalState,
  USER_TYPE,
} from '~utils';
import { OLD_USER_EMAILS, OLD_USERNAMES } from '~constants/old-users';
import { AuthContext } from '~providers/AuthProvider';
import { Button, NotificationModalWindow } from '~components/atoms';
import ChangePasswordDialog from '~components/popup/login/ChangePasswordDialog';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ResendEmailModal from '~components/organisms/popup/resend-email-modal';
import { showErrorToaster } from '~utils/toasterNotification';
import ReRegisterNotificationModal from '~components/organisms/popup/re-register-notification-modal';
import { useDebouncedCallback } from '~hooks/useDebouncedCallback';

const popupStylesCx = classNames.bind(popupStyles);

const CREDENTIALS_DEFAULT_VALUE = {
  password: '',
  username: '',
};

interface LoginTabProps {
  index: number;
  value: number;
  openSignupTab: () => void;
}

const LoginTab = (props: LoginTabProps) => {
  const { openSignupTab, value, index, ...other } = props;

  const { username: user } = useParams();
  const state = useGlobalState();
  const {
    artist: { username },
  } = state;
  const dispatch = useGlobalDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const agoraVideo = useContext(AgoraVideoContext);
  const { login } = useContext(AuthContext);
  const [credentials, setCredentials] = useState(CREDENTIALS_DEFAULT_VALUE);
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showChangePasswordDialog, setShowChangePasswordDialog] =
    useState(false);
  const [showResendEmailDialog, setShowResendEmailDialog] = useState(false);
  const [showResendSuccessDialog, setShowResendSuccessDialog] = useState(false);
  const [showReRegisterDialog, setShowReRegisterDialog] = useState(false);
  const [changePasswordKey, setChangePasswordKey] = useState('');
  const closeChangePasswordDialog = () => setShowChangePasswordDialog(false);
  const closeResendEmailDialog = () => setShowResendEmailDialog(false);
  const closeResendSuccessDialog = () => setShowResendSuccessDialog(false);
  const closeReRegisterDialog = () => setShowReRegisterDialog(false);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const resendEmail = async () => {
    closeResendEmailDialog();
    try {
      await resendActivationEmail(credentials.username, `/${username}`);
      setShowResendSuccessDialog(true);
    } catch (err) {
      showErrorToaster('Something went wrong. Try Again.');
    }
  };

  const onForgotPassword = () =>
    dispatch(
      isSubmitting || {
        type: 'app',
        payload: { popup: POPUP_TYPE.FORGOT_PWD },
      },
    );

  const handleChange =
    (prop: string) => (event: ChangeEvent<HTMLInputElement>) => {
      setCredentials((prevState) => ({
        ...prevState,
        [prop]: event.target.value,
      }));
    };

  async function leaveEvent() {
    const { connectionState: agoraVideo_connectionState } = agoraVideo || {};
    if (agoraVideo_connectionState === 'CONNECTED') await agoraVideo?.leave();

    const payload = {
      remoteUsers: [],
      isFanLive: false,
    };
    dispatch({
      type: 'config',
      payload,
    });
  }

  const getUserType = (userType: string, username: string) => {
    let calType = userType;
    if (userType === USER_TYPE.ARTIST && !!user) {
      if (!isTwoStringSame(username, user)) {
        calType = USER_TYPE.FAN;
      }
    }
    return calType;
  };

  const onReRegister = () => {
    closeReRegisterDialog();
    openSignupTab();
  };

  const handleLogin = useDebouncedCallback(async () => {
    const { username: loginUsername, password } = credentials;
    const {
      data: { userStatus, firstLogin, passwordResetKey },
    } = await verifyAccount(loginUsername, password);
    setIsSubmitting(true);
    if (OLD_USER_EMAILS.has(loginUsername?.toLowerCase())) {
      const { exists } = await checkEmailExist(loginUsername);
      if (!exists) {
        setShowReRegisterDialog(true);
        setIsSubmitting(false);
        return;
      }
    }

    if (OLD_USERNAMES.has(loginUsername?.toLowerCase())) {
      const { exists } = await checkUsernameExist(loginUsername);
      if (!exists) {
        setShowReRegisterDialog(true);
        setIsSubmitting(false);
        return;
      }
    }
    if (UserStatus.UNVERIFIED === userStatus) {
      setShowResendEmailDialog(true);
      setIsSubmitting(false);
      return;
    }
    if (firstLogin && passwordResetKey) {
      setChangePasswordKey(passwordResetKey);
      setShowChangePasswordDialog(true);
      setIsSubmitting(false);
      return;
    }
    await leaveEvent();
    setError('');
    try {
      await login(loginUsername, password, '');
      const userInfo = await getUserInfo();
      const {
        name,
        type: userType,
        displayUsername,
        imageUrl,
        username,
        id,
        isFollowing,
        walletBalance = 0,
        cashIn = 0,
        cashOut = 0,
        showLiveCoinPopup,
        customerId = '',
        email,
        phone,
        genre,
        description,
        outsideLinks,
        isPartner,
        onboardingInfoStep,
        transaction,
        showAddVideoToProfilePopup,
      } = userInfo;
      //If Artist want to login as fan by hit another artist url. below function set the type as fan of that artist
      const type = getUserType(userType, username);
      dispatch({
        type: 'user',
        payload: {
          name,
          type,
          originalType: userType,
          displayUsername,
          imageUrl,
          username,
          id,
          is_following: isFollowing,
          walletBalanceFan: walletBalance,
          walletBalanceInUsd: walletBalance / 10,
          cashIn,
          cashOut,
          customer_id: customerId,
          email,
          phone,
          showLiveCoinPopup,
          genre,
          description,
          outsideLinks,
          firstLogin,
          isPartner,
          onBoardingInfoStep: onboardingInfoStep,
          transaction,
          showAddVideoToProfilePopup,
        },
      });

      if (!location.pathname.includes('/user')) {
        navigate(user ? `/${user}` : '/');
      }

      dispatch({
        type: 'app',
        payload: { popup: POPUP_TYPE.NONE },
      });
    } catch (err: unknown) {
      setError(err?.toString() || '');
      setIsSubmitting(false);
    }
  });

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {error && (
        <Box
          sx={{ color: 'red' }}
          className={popupStylesCx('notification', 'success', {
            error: 'error',
          })}
        >
          {error}
        </Box>
      )}
      {value === index && (
        <Box display="flex" flexDirection="column" gap={1.5}>
          <Grid container>
            <GridItem item sx={{ mt: '15px' }} textAlign="left">
              <CustomLabel shrink htmlFor="username-input">
                Username/Email
              </CustomLabel>
            </GridItem>
            <GridItem item>
              <CustomInput
                size="small"
                fullWidth
                value={credentials.username}
                onChange={handleChange('username')}
              />
            </GridItem>
            <GridItem item sx={{ mt: '10px' }} textAlign="left">
              <CustomLabel shrink htmlFor="password-input">
                Password
              </CustomLabel>
            </GridItem>
            <GridItem item>
              <CustomInput
                size="small"
                fullWidth
                id="password-input"
                type={showPassword ? 'text' : 'password'}
                value={credentials.password}
                onChange={handleChange('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <Visibility sx={{ width: '20px', color: 'white' }} />
                      ) : (
                        <VisibilityOff
                          sx={{
                            width: '20px',
                            color: 'rgba(255, 255, 255, 0.3)',
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </GridItem>
          </Grid>
          <ForgotPassword onClick={onForgotPassword}>
            Trouble logging in?
          </ForgotPassword>
          <LoginButton
            disabled={Object.values(credentials).some((elem) => !elem)}
            onClick={handleLogin}
          >
            Login
          </LoginButton>
        </Box>
      )}
      <ChangePasswordDialog
        show={showChangePasswordDialog}
        onClose={closeChangePasswordDialog}
        passwordKey={changePasswordKey}
      />
      <ResendEmailModal
        resendEmail={resendEmail}
        onClose={closeResendEmailDialog}
        open={showResendEmailDialog}
      />
      <NotificationModalWindow
        open={showResendSuccessDialog}
        onClose={closeResendSuccessDialog}
        description={"We've sent an activation email to your email"}
      />
      <ReRegisterNotificationModal
        open={showReRegisterDialog}
        onClose={closeReRegisterDialog}
        onReRegister={onReRegister}
      />
    </div>
  );
};

const LoginButton = styled(Button)(({ theme }) => ({
  margin: '0 auto',
  fontSize: theme.typography.fontSize,
  padding: '7px 30px',
  color: theme.palette.text.secondary,
  width: '100%',
  marginTop: '30px',

  '&.Mui-disabled': {
    opacity: 0.2,
  },
}));

const CustomInput = styled(OutlinedInput)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '4px',
  height: 40,
  fontSize: theme.typography.subtitle1.fontSize,
  padding: '5px 10px',

  color: 'white',
  '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.action.active,
  },
  '.MuiInputBase-input:-webkit-autofill, .MuiInputBase-input:-webkit-autofill:focus':
    {
      '-webkit-text-fill-color': 'white',
      '-webkit-box-shadow': `0 0 0 9999px ${theme.palette.primary.main} inset !important`,
      backgroundColor: `${theme.palette.primary.main} !important`,
      backgroundClip: 'content-box !important',
    },
  '.MuiInputBase-input:-webkit-autofill::selection': {
    '-webkit-text-fill-color': 'white',
  },
}));

const CustomLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: theme.typography.subtitle1.fontSize,
  lineHeight: '20px',
  transform: 'none',
}));

const ForgotPassword = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  color: theme.palette.text.primary,
  opacity: 0.7,
  cursor: 'pointer',
}));

const GridItem = styled(Grid)(() => ({
  width: '100%',
}));

export default LoginTab;
