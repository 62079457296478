import React, { useEffect, useState } from 'react';
import { Box, styled } from '@mui/material';

import {
  ArrowContainer,
  NextArrowButtons,
  PrevArrowButtons,
  SlideWrapper,
} from '~components/pages/home/styles';

import Slider from 'react-slick';
import useBreakpoints from '~hooks/useBreakpoints';
import { ItemInSlide } from './item-in-slide';
import { CarouselDots } from '~components/molecules';
import { getOwnArchivedVideoRequest } from '~api/archived-videos';
import { Event } from '~types/event';
import { useDispatch } from 'react-redux';
import { RootDispatch } from '~stores';
import moment from 'moment';
import { AV_DURATION_CONDITION_FILTER } from '~utils/constants';

const VideoList = ({
  artistImage,
  total,
  filter,
}: {
  artistImage: string;
  total: number;
  filter: any;
}) => {
  const dispatch = useDispatch<RootDispatch>();

  const PROCESSED_SLIDES_IN_ROW_IN_RESPONSE = {
    xs: 1,
    sm: 2,
    md: 2,
    lg: 4,
    xl: 4,
  };

  const breakpoints = useBreakpoints();
  const ROWS_IN_RESPONSE = {
    xs: 3,
    sm: 3,
    md: 3,
    lg: 3,
    xl: 3,
  };
  const [limit, setLimit] = useState(
    PROCESSED_SLIDES_IN_ROW_IN_RESPONSE[breakpoints.active] *
      ROWS_IN_RESPONSE[breakpoints.active],
  );
  const RESPONSIVE = [
    {
      breakpoint: 576,
      settings: {
        arrows: false,
        slidesPerRow: PROCESSED_SLIDES_IN_ROW_IN_RESPONSE.xs,
      },
    },
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        slidesPerRow: PROCESSED_SLIDES_IN_ROW_IN_RESPONSE.md,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesPerRow: PROCESSED_SLIDES_IN_ROW_IN_RESPONSE.md,
      },
    },
  ];
  const [offset, setOffset] = useState(0);
  const [archivedVideos, setArchivedVideos] = useState<Event[]>([]);

  useEffect(() => {
    getOwnArchivedVideoRequest(
      /* TODO: Will improve the total here to the right query on the next PR - use query all for accomplish the time line */
      1000,
      0,
      filter.sort,
      filter.timeFrom.toISOString(),
      filter.timeTo.toISOString(),
    ).then((resp) => {
      const videos = resp.data.videos?.filter(
        (video) =>
          Math.abs(moment(video.endedAt).diff(video.startedAt, 'seconds')) >=
          AV_DURATION_CONDITION_FILTER,
      );
      setArchivedVideos(videos);
    });
  }, [filter]);

  useEffect(() => {
    setLimit(
      PROCESSED_SLIDES_IN_ROW_IN_RESPONSE[breakpoints.active] *
        ROWS_IN_RESPONSE[breakpoints.active],
    );
  }, [breakpoints.active]);

  useEffect(() => {
    if (total < offset) {
      setOffset(Math.ceil(total / limit) * limit - limit);
    }
    // refetch();
  }, [limit, offset, total]);

  const onRefreshEventList = (updatedEvent: Event) => {
    const updatedEvents = archivedVideos.map((e) => {
      if (e.id === updatedEvent.id) {
        return updatedEvent;
      } else {
        // Only 1 default video
        if (updatedEvent.defaultOnProfile) {
          return {
            ...e,
            defaultOnProfile: false,
          };
        }
        return e;
      }
    });
    setArchivedVideos(updatedEvents);
    // Dispatch data to other UIs
    dispatch.archivedVideos.setArchivedVideos([...updatedEvents]);
  };
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    slidesPerRow: PROCESSED_SLIDES_IN_ROW_IN_RESPONSE.xl,
    rows: ROWS_IN_RESPONSE[breakpoints.active],
    beforeChange: (oldIndex: number, newIndex: number) => {
      setOffset(newIndex * limit);
    },
    prevArrow: (
      <ArrowContainer>
        <PrevArrowButtons />
      </ArrowContainer>
    ),
    nextArrow: (
      <ArrowContainer>
        <NextArrowButtons />
      </ArrowContainer>
    ),
    responsive: RESPONSIVE,
    ...CarouselDots({
      sx: {
        mt: 1,
        color: 'white',
      },
    }),
  };

  return (
    <Box sx={{ mt: 4 }}>
      {archivedVideos.length > 0 && (
        <CustomSlider {...settings}>
          {archivedVideos.map((data, index) => {
            const { id } = data;
            return offset > index || index >= offset + limit ? (
              <SlideWrapper key={id} />
            ) : (
              <ItemInSlide
                key={id}
                data={data}
                onRefreshEventList={onRefreshEventList}
                artistImage={artistImage}
              />
            );
          })}
        </CustomSlider>
      )}
    </Box>
  );
};

export default VideoList;

const CustomSlider = styled(Slider)(({ theme }) => ({
  minHeight: 220,
  '.slick-dots': {
    paddingTop: 20,
    li: {
      margin: 0,
      paddingTop: 10,
    },
    div: {
      margin: 'auto',
    },
    '.slick-active div': {
      backgroundColor: theme.palette.text.primary,
    },
  },
}));
