import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, select } from '~stores';
import { useGlobalDispatch, useGlobalState } from '~utils/container';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { createPaymentIntent, handlePostPayment } from '~utils/stripePayment';
import { PaymentType, PaymentMethod } from '~types';
import { useBrazeEventPusher } from '~hooks/useBrazeEventPusher';

const useStripePayment = () => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useGlobalDispatch();
  const globalState = useGlobalState();
  const selectedLiveCoin = useSelector((state: RootState) =>
    select.payment.getSelectedLiveCoin(state),
  );
  const { user = {} } = globalState;
  const [isLoading, setIsLoading] = useState(true);
  const [isShowApplePay, setIsShowApplePay] = useState(false);
  const [isShowGooglePay, setIsShowGooglePay] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');
  const [paymentRequest, setPaymentRequest] = useState<any>(undefined);
  const paymentMethod = useRef<PaymentMethod | null>(null);
  const { pushPurchaseEvent } = useBrazeEventPusher();

  useEffect(() => {
    if (!selectedLiveCoin || !stripe || !elements) {
      return;
    }
    const paymentRequest = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'payment',
        amount: selectedLiveCoin?.[PaymentType.STRIPE].computedAmount || 0,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    // Check the availability of the Payment Request API.
    paymentRequest.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(paymentRequest);
        setIsShowApplePay(result.applePay);
        setIsShowGooglePay(result.googlePay);
      }
      setIsLoading(false);
    });

    paymentRequest.on('paymentmethod', async (event) => {
      if (!paymentMethod.current) {
        return;
      }
      let clientSecret = '';
      try {
        const res = await createPaymentIntent(
          selectedLiveCoin?.stripeProductId,
        );
        clientSecret = res.clientSecret;
      } catch (err) {
        setErrorMessage('Something went wrong. Try Again.');
        return;
      }

      const { error: confirmError, paymentIntent } =
        await stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: event.paymentMethod.id,
          },
          { handleActions: false },
        );

      if (confirmError) {
        event.complete('fail');
        setErrorMessage(confirmError.message);
        return;
      }

      if (paymentIntent?.status === 'requires_action') {
        const { error } = await stripe.confirmCardPayment(clientSecret);
        if (error) {
          setErrorMessage(error.message);
        } else {
          await handlePostPayment(
            paymentIntent,
            selectedLiveCoin,
            dispatch,
            user,
            paymentMethod.current,
          );
          pushPurchaseEvent({
            productId: selectedLiveCoin.stripeProductId,
            price: selectedLiveCoin.amount,
          });
        }
      } else {
        await handlePostPayment(
          paymentIntent,
          selectedLiveCoin,
          dispatch,
          user,
          paymentMethod.current,
        );
        pushPurchaseEvent({
          productId: selectedLiveCoin.stripeProductId,
          price: selectedLiveCoin.amount,
        });
      }
      event.complete('success');
    });
  }, [
    dispatch,
    elements,
    globalState,
    selectedLiveCoin,
    setPaymentRequest,
    stripe,
    user,
  ]);

  const showPaymentRequest = useCallback(
    (payment: PaymentMethod) => {
      paymentMethod.current = payment;
      paymentRequest.show();
    },
    [paymentRequest],
  );

  return {
    isLoading,
    isShowApplePay,
    isShowGooglePay,
    paymentRequest,
    errorMessage,
    showPaymentRequest,
  };
};
export default useStripePayment;
