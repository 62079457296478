import { useLocation } from 'react-router-dom';
import { useGlobalState, USER_TYPE } from '~utils';

const useAccount = () => {
  const location = useLocation();
  const {
    user: { id, username, type, originalType },
  } = useGlobalState();
  const isLogin = !!id;
  const isViewer = type === USER_TYPE.FAN;
  const isHost = type === USER_TYPE.ARTIST;
  const isArtistAccount = originalType === USER_TYPE.ARTIST;
  const isFanAccount = originalType === USER_TYPE.FAN;
  const isAccessingOwnChannel = location.pathname.includes('/user');
  return {
    isLogin,
    username,
    isViewer,
    isHost,
    type,
    userId: id,
    isArtistAccount,
    isFanAccount,
    originalType,
    isAccessingOwnChannel,
  };
};
export default useAccount;
