import { useEffect, ReactNode } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//
import { RootDispatch, RootState, select } from '~stores';
import { AxiosError } from 'axios';
//
import { RoutesPages } from '../../router/types';
import { useGlobalState } from '~utils/container';
import { USER_TYPE } from '~utils/constants';
import { useBoolean } from '~hooks/useBoolean';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
import MenuPopupsContainer from '~components/organisms/account/menu-popups-container';

type FetchArtistInfoProps = {
  children: ReactNode;
};

const FetchArtistInfo = ({ children }: FetchArtistInfoProps) => {
  const { username: artistName } = useParams();
  const { user } = useGlobalState();
  const { showLoginPopup } = useDispatchPopup();
  const isFetchedArtistInfo = useSelector((state: RootState) =>
    select.roomInfo.isFetchedArtistInfo(state),
  );
  const dispatch = useDispatch<RootDispatch>();
  const location = useLocation();
  const navigate = useNavigate();
  const isPersonalPage = useBoolean(false);

  useEffect(() => {
    const handleFetchArtistInfo = async () => {
      if (location.pathname.includes('/user')) {
        isPersonalPage.onTrue();
        const { username, originalType } = user;
        try {
          // This case happened when user is already login.
          if (username) {
            if (originalType === USER_TYPE.ARTIST) {
              await dispatch.roomInfo?.fetchArtistInfo(username);
            } else {
              // TODO
            }
          } else {
            showLoginPopup();
          }
        } catch (error) {
          // TODO: nothing
        }
      } else {
        const username = artistName as string;
        try {
          await dispatch.roomInfo?.fetchArtistInfo(username);
        } catch (error) {
          const { response } = error as AxiosError<{
            code: number;
            message: string;
          }>;
          if (response?.data.code === 403) {
            navigate(RoutesPages.ACCESS_DENIED);
            return;
          }
          if (username) {
            navigate(RoutesPages.NOT_FOUND);
          } else {
            navigate(RoutesPages.HOME);
          }
        }
      }
    };
    if (!isFetchedArtistInfo) {
      handleFetchArtistInfo();
    }
  }, [artistName, dispatch.roomInfo, isFetchedArtistInfo, navigate, user]);

  return (
    <>
      <MenuPopupsContainer />
      {isFetchedArtistInfo && children}
    </>
  );
};

export default FetchArtistInfo;
