import { Button, ButtonProps, StackProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { HighlightText } from '~components/molecules';
import useAccount from '~hooks/useAccount';
import { USER_TYPE, useGlobalState } from '~utils/index';

type Props = ButtonProps & {
  disabled?: boolean;
  onClick: () => void;
  highlightTextProps?: StackProps;
};

const DEFAULT_SESSION_VALUE = '00:00:00';

const AccountActions = ({
  disabled,
  onClick,
  highlightTextProps,
  ...rest
}: Props) => {
  const { username: paramUsername } = useParams();
  const { isAccessingOwnChannel, username: artistStateName } = useAccount();
  const artistName = isAccessingOwnChannel ? artistStateName : paramUsername;

  const globalState = useGlobalState();
  const {
    user: { type, username, originalType },
    config: { isArtistLive, startLiveAt },
    // TODO need to define data type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = globalState as Record<string, any>;

  const isArtist = isAccessingOwnChannel
    ? originalType === USER_TYPE.ARTIST
    : type === USER_TYPE.ARTIST && username === artistName;

  const [sessionLiveTime, setSessionLiveTime] = useState(DEFAULT_SESSION_VALUE);

  useEffect(() => {
    let interval: string | number | NodeJS.Timer | null | undefined = null;
    if (startLiveAt && isArtistLive) {
      interval = setInterval(() => {
        const start = new Date(startLiveAt).getTime();
        const end = new Date().getTime();
        const diff = end - start;
        const delta = Math.abs(diff) / 1000;
        const hours = Math.floor(delta / 3600) % 24;
        const minutes = Math.floor(delta / 60) % 60;
        const seconds = Math.floor(delta % 60);

        const formatTimeString = (value: number) => {
          return value < 10 ? '0' + value : value;
        };

        setSessionLiveTime(
          [
            formatTimeString(hours),
            formatTimeString(minutes),
            formatTimeString(seconds),
          ].join(':'),
        );
      }, 1000);
    }
    if (!isArtistLive) {
      if (interval) {
        clearInterval(interval);
      }
      setSessionLiveTime(DEFAULT_SESSION_VALUE);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [startLiveAt, isArtistLive]);

  if (!isArtist) return null;

  return (
    <>
      {isArtistLive && (
        <HighlightText
          title="Session:"
          text={sessionLiveTime}
          {...highlightTextProps}
        />
      )}
      <Button
        variant="contained"
        color="error"
        disabled={disabled}
        onClick={onClick}
        {...rest}
      >
        {isArtistLive ? 'END LIVECAST' : 'GO LIVE'}
      </Button>
    </>
  );
};

export default AccountActions;
