import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { usePayPalScriptReducer } from '@paypal/react-paypal-js';
import {
  CreateOrderActions,
  CreateOrderData,
  OnApproveActions,
  OnApproveData,
} from '@paypal/paypal-js/types/components/buttons';
import { OrderResponseBody } from '@paypal/paypal-js/types/apis/orders';
import { handlePostPaypalPayment } from '~services/paymentService';
import { useGlobalDispatch, useGlobalState } from '~utils/container';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
import { select } from '~stores';
import { PaymentType } from '~types';
import { useBrazeEventPusher } from '~hooks/useBrazeEventPusher';

const usePaypalPayment = () => {
  const dispatch = useGlobalDispatch();
  const globalState = useGlobalState();
  const selectedLiveCoin = useSelector(select.payment.getSelectedLiveCoin);
  const showTipPopup = useSelector(select.payment.getShowTipPopup);
  const { showThankPopup, hidePopup } = useDispatchPopup();
  const { user = { type: '' } } = globalState;
  const [{ isResolved }] = usePayPalScriptReducer();
  const { pushPurchaseEvent } = useBrazeEventPusher();

  const handleToggleTipPopup = useCallback(() => {
    if (showTipPopup) {
      showThankPopup();
    } else {
      hidePopup();
    }
  }, [hidePopup, showThankPopup, showTipPopup]);

  const createOrder = useCallback(
    (data: CreateOrderData, actions: CreateOrderActions) => {
      return actions.order.create({
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'USD',
              value: (
                selectedLiveCoin?.[PaymentType.PAYPAL].computedAmount || 0
              ).toString(),
            },
          },
        ],
      });
    },
    [selectedLiveCoin],
  );

  const onApprove = useCallback(
    (
      data: OnApproveData,
      actions: OnApproveActions,
      onSuccess?: () => void,
    ) => {
      if (selectedLiveCoin && actions?.order?.capture) {
        return actions?.order
          ?.capture()
          .then(async (details: OrderResponseBody) => {
            !!onSuccess && (await onSuccess());
            await handlePostPaypalPayment(
              details,
              selectedLiveCoin,
              dispatch,
              user,
              handleToggleTipPopup,
            );
            pushPurchaseEvent({
              productId: selectedLiveCoin.stripeProductId,
              price: selectedLiveCoin.amount,
            });
          });
      }
      return Promise.resolve();
    },
    [dispatch, selectedLiveCoin, handleToggleTipPopup, user],
  );

  return {
    isResolved,
    createOrder,
    onApprove,
  };
};
export default usePaypalPayment;
