import { Box, BoxProps, Grid, Stack, styled, Typography } from '@mui/material';
import React from 'react';
import { NoPhoto } from '~components/icons';
import isUrl from 'is-url';
import { useDispatch } from 'react-redux';
import { RootDispatch } from '~stores';
import { Event } from '~types';
import moment from 'moment';
import { getCdnURL, getImageURL } from '~utils/awsUtils';

type Props = {
  isDragging: boolean;
  event: Event;
  artistImage?: string;
} & BoxProps;

const ArchivedVideoCardItem = ({ event, artistImage, ...boxProps }: Props) => {
  const dispatch = useDispatch<RootDispatch>();
  const onVideoClick = () => {
    dispatch.archivedVideos.setCurrentVideo(event);
  };
  const duration = moment
    .utc(
      Math.abs(
        moment(new Date(event.startedAt)).diff(
          new Date(event.endedAt),
          'milliseconds',
        ),
      ),
    )
    .format('HH:mm:ss');
  const imageUrl = getCdnURL(event.imageUrl);
  const artistImageUrl = getImageURL(artistImage);

  return (
    <Grid container maxWidth={300}>
      <Grid xs={8} sm={8} md={12} lg={12} xl={12} item>
        <Box
          {...boxProps}
          position="relative"
          onClick={onVideoClick}
          sx={{ cursor: 'pointer' }}
        >
          <ThumbNailContainer sx={{ background: 'rgba(0,0,0,0)' }}>
            <Container borderRadius={5}>
              <Box
                sx={{
                  position: 'absolute',
                  top: 5,
                  bottom: 5,
                  left: 5,
                  right: 5,
                  borderRadius: 20,
                  backgroundImage: `url(${imageUrl || artistImageUrl})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  filter: 'blur(6px)',
                  zIndex: 0,
                  overflow: 'hidden',
                }}
              />
              {isUrl(imageUrl) || isUrl(artistImageUrl) ? (
                <img
                  style={{ zIndex: 1, objectFit: 'contain', borderRadius: 20 }}
                  width="100%"
                  height="100%"
                  src={imageUrl || artistImageUrl}
                  alt="photo"
                />
              ) : (
                <NoPhoto />
              )}
            </Container>
            <OverlayContent>
              <Typography
                sx={{
                  paddingLeft: 1,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {moment(new Date(event.scheduledAt)).format('MM/DD/YYYY')}
              </Typography>
              <Typography
                sx={{
                  paddingLeft: 1,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {duration}
              </Typography>
            </OverlayContent>
          </ThumbNailContainer>
        </Box>
      </Grid>
      <Grid
        xs={4}
        sm={4}
        md={12}
        lg={12}
        xl={12}
        display={'flex'}
        justifyContent={'start'}
        alignItems={'center'}
        item
      >
        <Typography
          sx={{
            color: 'white',
            paddingLeft: 1,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {event.videoTitle || event.name}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ArchivedVideoCardItem;

const ThumbNailContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: 250,
  },
  height: 150,
  overflow: 'hidden',
}));

const Container = styled(Stack)(() => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  justifyContent: 'center',
  alignItems: 'center',
  background: '#434343',
}));

const OverlayContent = styled(Stack)(() => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1,
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  padding: '4px 8px',
  borderRadius: '0px 0px 20px 20px',
}));
