import { useEffect, useCallback } from 'react';
import * as braze from '@braze/web-sdk';
import useAccount from '~hooks/useAccount';

export enum BrazeEventType {
  USER_TIP_TO_ARTIST = 'USER_TIP_TO_ARTIST',
  USER_SEND_CHAT_TO_ARTIST = 'USER_SEND_CHAT_TO_ARTIST',
  USER_SEND_QUESTION_TO_ARTIST = 'USER_SEND_QUESTION_TO_ARTIST',
  USER_VIEW_ARCHIVED_VIDEO = 'USER_VIEW_ARCHIVED_VIDEO',
  USER_CLICK_DOWNLOAD_EVENT_INVITATION = 'USER_CLICK_DOWNLOAD_EVENT_INVITATION',
  USER_ATTEND_EVENT = 'USER_ATTEND_EVENT',
  ARTIST_GO_LIVE = 'ARTIST_GO_LIVE',
  ARTIST_SCHEDULE_EVENT = 'ARTIST_SCHEDULE_EVENT',
  ARTIST_END_EVENT = 'ARTIST_END_EVENT',
  ARTIST_RECEIVED_TIP = 'ARTIST_RECEIVED_TIP',
}

type PurchaseEvent = {
  productId: string;
  price: number;
};

type BrazeEventPusherProps = {
  pushBrazeEvent: (eventName: string, properties?: Record<string, any>) => void;
  pushPurchaseEvent: (data: PurchaseEvent) => void;
  changeUser: (userId: string) => void;
  openSession: () => void;
};

export const useBrazeEventPusher = (): BrazeEventPusherProps => {
  const { userId } = useAccount();

  const logCustomEvent = useCallback(
    (eventName: string, properties?: Record<string, any>) => {
      if (!eventName) return;

      try {
        if (properties) {
          braze.logCustomEvent(eventName, properties);
        } else {
          braze.logCustomEvent(eventName);
        }
      } catch (error) {
        // TODO
      }
    },
    [],
  );

  const pushPurchaseEvent = useCallback((data: PurchaseEvent) => {
    braze.logPurchase(data.productId, data.price, 'USD', 1);
  }, []);

  const changeUser = useCallback((newUserId: string) => {
    braze.changeUser(newUserId);
  }, []);

  const openSession = useCallback(() => {
    braze.openSession();
  }, []);

  useEffect(() => {
    if (userId) {
      changeUser(userId);
      openSession();
    }
  }, [userId, changeUser, openSession]);

  return {
    pushBrazeEvent: logCustomEvent,
    changeUser,
    openSession,
    pushPurchaseEvent,
  };
};
