import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  OnApproveActions,
  OnApproveData,
  OnShippingChangeActions,
  OnShippingChangeData,
} from '@paypal/paypal-js/types/components/buttons';

import {
  Alert,
  alpha,
  Box,
  CircularProgress,
  Grid,
  Stack,
  styled,
  SxProps,
  Theme,
  Backdrop,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripeCardNumberElement } from '@stripe/stripe-js';
import { PayPalButtons } from '@paypal/react-paypal-js';
import {
  CustomRadioGroup,
  FormProvider,
  NewPaymentReceipt,
} from '~components/molecules';
import {
  ApplePayIcon,
  GooglePayIcon,
  PaypalColorfulIcon,
} from '~components/icons';
import { POPUP_TYPE, useGlobalDispatch, useGlobalState } from '~utils';
import {
  LiveCoinOptionType,
  PaymentMethodEnum,
  PaymentType,
  PaymentMethod,
} from '~types';
import { PaymentCard } from '~types/payment';
import { select } from '~stores';
import {
  calculateTax,
  checkPostalCode,
  getLastPaymentInfo,
  payWithTax,
  updateAddresses,
} from '~api/payment';
import { getFanDetails, saveTransactionWithTax } from '~components/action';
import useNewStripePayment from '~components/custom-hook/useNewStripePayment';
import usePaypalPayment from '~components/custom-hook/usePaypalPayment';

import BasePopup from '../base-popup';
import PaymentCardList from './payment-card-list';
import { get } from 'lodash';
import PaymentReview from './payment-preview';
import { formatDateTime } from '~utils/dateTimeUtils';
import { LoadingButton } from '~components/atoms';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '~constants/query-keys';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
import { isAxiosError } from 'axios';
import Captcha from '~components/captcha/Captcha';
import { useCaptcha } from '~components/custom-hook';
import { getPurchaseHistory } from '~api/transaction';
import moment from 'moment';
import { useBrazeEventPusher } from '~hooks/useBrazeEventPusher';

const StyledPaymentModal = styled(BasePopup)<{ success: boolean }>(
  ({ theme, success }) => ({
    backgroundColor: theme.palette.primary.light,
    [theme.breakpoints.up('xs')]: {
      marginTop: success ? 'auto' : theme.spacing(2),
      marginBottom: success ? 'auto' : theme.spacing(2),
      width: 'calc(100% - 32px)',
      padding: `${theme.spacing(success ? 4 : 2)} ${theme.spacing(2)}`,
    },
    [theme.breakpoints.up('md')]: {
      width: success ? 'initial' : '90%',
      marginBottom: 'auto',
      marginTop: 'auto',
      padding: `${theme.spacing(4)} ${theme.spacing(4)}`,
    },
    [theme.breakpoints.up('lg')]: {
      padding: `${theme.spacing(4)} ${theme.spacing(7.5)}`,
    },
  }),
);

const EnhancedBox = styled(Box)<{ optimized?: boolean }>(({ optimized }) => ({
  height: optimized ? 100 : 115,
}));

const paymentMethodSx = (
  active: boolean,
  isOptimized?: boolean,
): SxProps<Theme> => ({
  borderRadius: (theme) => theme.spacing(1),
  border: (theme) =>
    `1px solid ${
      active ? alpha(theme.palette.common.white, 0.1) : 'transparent'
    }`,
  backgroundColor: (theme) => alpha(theme.palette.primary.dark, 0.58),
  minHeight: { xs: 'auto', md: 78 },
  marginRight: 0,
  padding: {
    xs: isOptimized ? '9px 20px' : '16px 20px',
    md: (theme) => theme.spacing(2.5),
  },
});

type FormValuesProps = {
  selectedCard: PaymentCard | null;
  isSaved: boolean;
  receiptDate: string;
  stripeError: string | null;
};

const PaymentModal = () => {
  const globalState = useGlobalState();
  // TODO need to define data type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { user } = globalState as Record<string, any>;
  const selectedLiveCoin = useSelector(
    select.payment.getSelectedLiveCoin,
  ) as LiveCoinOptionType;
  const {
    captchaVerified,
    onCaptchaVerified,
    onCaptchaExpired,
    captchaRef,
    captchaToken,
  } = useCaptcha();
  const showTipPopup = useSelector(select.payment.getShowTipPopup);
  const { displayUsername, customer_id } = user;
  const dispatch = useGlobalDispatch();
  const { showThankPopup } = useDispatchPopup();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [currentMethod, setCurrentMethod] = useState<PaymentMethodEnum | null>(
    null,
  );
  const [errorMessage, setErrorMessage] = useState<null | string>('');
  const [latestCardId, setLatestCardId] = useState<string>();
  const [isInitializing, setIsInitializing] = useState(false);
  const [paymentReceiptData, setPaymentReceiptData] = useState<any>();
  const [paymentAddress, setPaymentAddress] = useState<any>();
  const [isShowCaptcha, setIsShowCaptcha] = useState(false);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const isOptimizedSpace = isXs && currentMethod === PaymentMethodEnum.card;
  const { isResolved, createOrder: onCreatePaypalOrder } = usePaypalPayment();
  const {
    isShowApplePay,
    isShowGooglePay,
    showPaymentRequest,
    isLoading: isStripeLoading,
  } = useNewStripePayment();
  const paypalTaxAmountRef = useRef<any>();
  const POSTAL_CODE_NOT_VALID_ERROR_MESSAGE =
    '\n Postal / Zip code is not valid in this state';
  const { pushPurchaseEvent } = useBrazeEventPusher();
  const paypalApproveCallbackDecorator = (
    data: OnApproveData,
    actions: OnApproveActions,
  ) => {
    if (selectedLiveCoin && actions?.order?.capture) {
      return actions?.order?.capture().then(async () => {
        handleStoreTransaction(
          data.orderID,
          PaymentMethod.PAYPAL,
          PaymentType.PAYPAL,
          undefined,
        );
      });
    }

    return Promise.resolve();
  };

  const onPaypalShippingChange = async (
    data: OnShippingChangeData,
    actions: OnShippingChangeActions,
  ) => {
    const amount = selectedLiveCoin?.[PaymentType.PAYPAL].computedAmount || 0;
    const address = data.shipping_address;

    let taxAmount = 0;
    try {
      taxAmount =
        (await calculateTax({
          line1: '',
          city: address?.city,
          state: address?.state,
          postalCode: address?.postal_code,
          country: address?.country_code,
          amount: amount * 100,
        }).then((resp) => resp.data.taxAmount)) / 100;
    } catch (e) {
      taxAmount = 0;
    }
    paypalTaxAmountRef.current = taxAmount;
    setPaymentReceiptData({
      ...paymentReceiptData,
      tax: taxAmount,
      total: paymentReceiptData.price + taxAmount,
    });
    return actions.order.patch([
      {
        op: 'replace',
        path: "/purchase_units/@reference_id=='default'/amount",
        value: {
          currency_code: 'USD',
          value: (amount + taxAmount).toFixed(2),
          breakdown: {
            item_total: {
              value: amount.toString(),
              currency_code: 'USD',
            },
            tax_total: {
              value: taxAmount.toFixed(2),
              currency_code: 'USD',
            },
          },
        },
      },
    ]);
  };

  useQuery({
    queryKey: [QUERY_KEYS.PURCHASE_HISTORY],
    queryFn: () => getPurchaseHistory(user.id, 1),
    refetchOnWindowFocus: true,
    onSuccess: (data: any) => {
      if (data.transactions && data.transactions.length > 0) {
        const latestTransaction = data.transactions[0];
        const transactionTime = moment(latestTransaction.createdAt);
        const oneDayAgo = moment().subtract(1, 'days');
        setIsShowCaptcha(transactionTime.isAfter(oneDayAgo));
      } else {
        setIsShowCaptcha(false);
      }
    },
  });

  const methods = useForm<FormValuesProps>({
    defaultValues: {
      isSaved: false,
      selectedCard: null,
      receiptDate: formatDateTime(new Date().toISOString(), 'DD'),
      stripeError: null,
    },
  });

  const { handleSubmit, watch, setValue } = methods;

  const isSaved = watch('isSaved');
  const selectedCard = watch('selectedCard');
  const receiptDate = watch('receiptDate');
  const openPreview = paymentSuccess;

  const METHODS: any[] = useMemo(
    () =>
      [
        {
          label: 'PayPal',
          name: 'payment-method',
          value: PaymentMethodEnum.paypal,
          icon: <PaypalColorfulIcon />,
          visible: isResolved,
        },
        {
          label: 'Apple Pay',
          name: 'payment-method',
          value: PaymentMethodEnum.applePay,
          icon: <ApplePayIcon />,
          visible: isShowApplePay,
        },
        {
          label: 'Google Pay',
          name: 'payment-method',
          value: PaymentMethodEnum.googlePay,
          icon: <GooglePayIcon />,
          visible: isShowGooglePay,
        },
      ].filter((o) => o.visible),
    [isShowApplePay, isResolved, isShowGooglePay],
  );

  const paymentType = useMemo(() => {
    switch (currentMethod as PaymentMethodEnum) {
      case PaymentMethodEnum.applePay:
      case PaymentMethodEnum.googlePay:
      case PaymentMethodEnum.card:
        return PaymentType.STRIPE;
      case PaymentMethodEnum.paypal:
        return PaymentType.PAYPAL;
      default:
        return null;
    }
  }, [currentMethod]);

  useEffect(() => {
    let displayAmount, productId;
    if (paymentType !== null) {
      const { displayAmount: temptDisplayAmount, productId: temptProductId } =
        get(
          selectedLiveCoin,
          paymentType, // STRIPE | PAYPAL
        );

      displayAmount = temptDisplayAmount;
      productId = temptProductId;
    }

    setPaymentReceiptData({
      name: displayUsername,
      date: receiptDate,
      item: `${selectedLiveCoin.live_coins} lc`,
      price: selectedLiveCoin?.amount,
      tax: currentMethod == PaymentMethodEnum.card ? null : -99,
      fee: 0,
      total: displayAmount || '0',
      productId: productId,
    });
  }, [displayUsername, selectedLiveCoin, receiptDate, paymentType]);

  const handleChange = (value: string) => {
    if (value !== 'card') {
      // Reset card
      setValue('selectedCard', null);
    }
    setCurrentMethod(value as PaymentMethodEnum);
  };

  const handleClose = () => {
    dispatch({
      type: 'app',
      payload: { popup: POPUP_TYPE.NONE },
    });
  };

  const handleError = (error: unknown) => {
    if (errorMessage) {
      setErrorMessage(null);
    }
    let message = 'Something went wrong. Try Again.';
    if (isAxiosError(error) && error.response?.data?.message) {
      const errRespMessage = error.response?.data?.message;
      // This is for handling error message of the case payment address is null but user try to spam on pay button
      if (errRespMessage.includes('convert argument')) {
        message = 'Please add address before process payment';
      }
    }

    setLoading(false);
    setErrorMessage(message);
  };

  const handleStoreTransaction = async (
    paymentIntentId: string,
    paymentMethod: PaymentMethod,
    paymentType: PaymentType,
    cardId?: string,
  ) => {
    // !!! Be aware !!!
    // Tax amount is different between stripe & paypal:
    // 1. card payment could retrieve the tax amount from paymentReceiptData
    // 2. paypal payment need to retrieve through ref (because the callback can't access the latest state of paymentReceiptData)
    const taxAmount =
      currentMethod === PaymentMethodEnum.paypal
        ? paypalTaxAmountRef.current.toFixed(2)
        : Number(paymentReceiptData.tax).toFixed(2);

    try {
      const response = await saveTransactionWithTax(
        paymentIntentId,
        selectedLiveCoin.live_coins,
        paymentType,
        selectedLiveCoin.stripeProductId,
        paymentMethod,
        cardId,
        taxAmount,
      );
      pushPurchaseEvent({
        productId: selectedLiveCoin.stripeProductId,
        price: selectedLiveCoin.amount,
      });
      await getFanDetails(dispatch, { user });
      if (response && response.status === 200) {
        setValue('receiptDate', formatDateTime(response.data.createdAt, 'DD'));
        // the updating address only needed for the saved card method
        // Other payment methods: paypal, google, apple could handle address by themself
        if (currentMethod === PaymentMethodEnum.card && selectedCard?.id) {
          await updateAddresses({
            payment_address: {
              ...paymentAddress,
              paymentMethodId: selectedCard?.id,
            },
          });
        }

        setPaymentSuccess(true);
        setLoading(false);
      }
    } catch (error) {
      handleError(error as string);
    }
  };

  const handlePayLiveCoin = async (
    customerId: string,
    paymentMethodId: string,
    captchaToken: string | null,
  ) => {
    try {
      setLoading(true);
      const response = await payWithTax(
        customerId,
        paymentMethodId,
        selectedLiveCoin.stripeProductId,
        paymentReceiptData.tax * 100,
        paymentAddress,
        captchaToken,
      );

      if (response && response.status === 200) {
        await handleStoreTransaction(
          response.data.paymentIntentId,
          PaymentMethod.CARD,
          PaymentType.STRIPE,
          customerId ? paymentMethodId : undefined,
        );
      }
    } catch (error) {
      handleError(error as string);
    }
  };

  const handleCreateStripePaymentMethod = async () => {
    try {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      if (!stripe || !elements) return;

      // If don't create new when user input info

      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement) as StripeCardNumberElement,
        billing_details: {
          // Include any additional collected billing details.
          name: displayUsername,
          address: {
            line1: paymentAddress.line1,
            line2: paymentAddress.line2,
            city: paymentAddress.city,
            postal_code: paymentAddress.postalCode,
            state: paymentAddress.state,
            country: paymentAddress.country,
          },
        },
      });

      if (result.error) {
        throw result.error.message;
      }
      setValue('stripeError', null);
      // When card created successfully process pay
      return result.paymentMethod;
    } catch (error) {
      setValue('stripeError', error as string);
    }
  };

  const handleCardPayment = async () => {
    try {
      // If user has card process pay
      if (selectedCard) {
        await handlePayLiveCoin(customer_id, selectedCard.id, captchaToken);
        return;
      }

      const stripePaymentMethod = await handleCreateStripePaymentMethod();

      // When card created successfully process pay
      if (stripePaymentMethod) {
        await handlePayLiveCoin(
          isSaved ? customer_id : undefined,
          stripePaymentMethod.id,
          captchaToken,
        );
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleProcessPayment = async () => {
    switch (currentMethod) {
      case PaymentMethodEnum.card:
        setPaymentSuccess(false);
        await handleCardPayment();
        break;

      case PaymentMethodEnum.applePay:
        showPaymentRequest(PaymentMethod.APPLE_PAY);
        break;
      case PaymentMethodEnum.googlePay:
        showPaymentRequest(PaymentMethod.GOOGLE_PAY);
        break;
      default:
        break;
    }
  };

  const handleToggleTipPopup = () => {
    if (!paymentSuccess) return;
    handleClose();
    if (showTipPopup) {
      showThankPopup();
    }
  };

  const handleStripeAddressChange = async (data: any) => {
    const address = data?.value?.address;
    const convertedValue: any = {
      line1: address?.line1,
      line2: address?.line2,
      city: address?.city,
      state: address?.state,
      postalCode: address?.postal_code,
      country: address?.country,
      amount: selectedLiveCoin.amount * 100,
      complete: data?.complete,
      fullname: data?.value?.name,
      id: address.id,
    };

    setPaymentAddress(convertedValue);

    if (
      data.complete &&
      convertedValue &&
      convertedValue.state &&
      convertedValue.country &&
      convertedValue.city
    ) {
      setErrorMessage('');
      setLoading(true);
      try {
        const postalCheckingResponse = await checkPostalCode(
          convertedValue.postalCode,
          convertedValue.country,
        );
        const places = postalCheckingResponse.places;
        const isValidatedState =
          places &&
          places.length > 0 &&
          places[0].stateAbbreviation === convertedValue.state;
        if (isValidatedState) {
          const taxAmount =
            (await calculateTax(convertedValue).then(
              (resp) => resp.data.taxAmount,
            )) / 100;
          setPaymentReceiptData({
            ...paymentReceiptData,
            tax: taxAmount,
            total: paymentReceiptData.price + taxAmount,
          });
          setErrorMessage('');
        } else {
          setErrorMessage(POSTAL_CODE_NOT_VALID_ERROR_MESSAGE);
          setPaymentReceiptData({
            ...paymentReceiptData,
            tax: null,
            total: selectedLiveCoin?.amount,
          });
        }
        setLoading(false);
      } catch (err) {
        setErrorMessage(
          'We could not determine your location, Please refill your address.',
        );
        setPaymentAddress({
          ...convertedValue,
          complete: false,
        });
      }
    }
  };

  const onSubmit = async () => {
    if (!currentMethod) {
      setErrorMessage('Please select the payment method');
      return;
    }
    if (errorMessage === POSTAL_CODE_NOT_VALID_ERROR_MESSAGE) {
      return;
    }
    setErrorMessage('');
    if (
      paymentAddress &&
      !paymentAddress.complete &&
      currentMethod === PaymentMethodEnum.card
    ) {
      let validatedMessage = '';
      let errorCount = 0;
      let errorFields = '';
      Object.entries(paymentAddress).forEach((entry) => {
        const key = entry[0];
        const value = entry[1];
        if ((!value || value === '') && key != 'line2') {
          if (key !== 'complete' && key !== 'amount') {
            let field;
            switch (key) {
              case 'postalCode':
                field = 'Postal / Zip';
                break;
              case 'line1':
                field = 'Address line 1';
                break;
              case 'fullname':
                field = 'Full name';
                break;
              case 'country':
                field = 'Country or region';
                break;
              case 'state':
                field = 'State';
                break;
              case 'city':
                field = 'City';
                break;
              default:
                field = key;
                break;
            }
            errorFields = errorFields.concat(` [${field}]`);
            errorCount++;
          }
        }
      });
      const isPlural = errorCount > 1;
      validatedMessage = `
      Please fill ${isPlural ? 'these' : 'this'} field${isPlural ? 's' : ''} : 
      `.concat(errorFields);

      setErrorMessage(
        errorCount === 0 ? 'Please correct the information' : validatedMessage,
      );
    } else {
      // Add new card
      handleProcessPayment();
    }
  };

  useQuery({
    queryKey: [QUERY_KEYS.LATEST_PAYMENT_INFO],
    queryFn: () => getLastPaymentInfo(),
    enabled: !isStripeLoading,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const { paymentMethod, metadata } = data;
      switch (paymentMethod) {
        case PaymentMethod.CARD:
          setCurrentMethod(PaymentMethodEnum.card);
          if (metadata?.['cardId']) {
            setLatestCardId(metadata['cardId']);
          }
          break;
        case PaymentMethod.PAYPAL:
          setCurrentMethod(PaymentMethodEnum.paypal);
          break;
        case PaymentMethod.APPLE_PAY:
          if (isShowApplePay) {
            setCurrentMethod(PaymentMethodEnum.applePay);
          }
          break;
        case PaymentMethod.GOOGLE_PAY:
          if (isShowGooglePay) {
            setCurrentMethod(PaymentMethodEnum.googlePay);
          }
          break;
      }
      if (metadata?.['cardId']) {
        setLatestCardId(metadata['cardId']);
      }
      setIsInitializing(true);
    },
  });

  if (isStripeLoading || !isInitializing) {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="secondary" />
      </Backdrop>
    );
  }
  const captchaCondition = isShowCaptcha && { disabled: !captchaVerified };
  return (
    <StyledPaymentModal
      open={true}
      onClose={handleClose}
      success={openPreview}
      closeBtn={openPreview && !errorMessage}
      title={openPreview ? '' : 'Payment'}
    >
      <Box
        sx={{
          mt: openPreview ? 0 : { xs: 1, sm: 5 },
        }}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          {openPreview && (
            <PaymentReview
              title={'Thank you for your purchase!'}
              data={paymentReceiptData}
              btnText={'NEXT'}
              onClick={handleToggleTipPopup}
            />
          )}
          <Box display={openPreview ? 'none' : 'block'}>
            <Grid
              container
              spacing={{
                xs: isOptimizedSpace ? 1 : 2,
                sm: 3.5,
                xl: 5,
              }}
            >
              <Grid item xs={12} sm={6} md={7}>
                <Stack spacing={{ xs: 1, md: 2 }} justifyContent={'center'}>
                  {errorMessage && (
                    <Alert
                      severity="error"
                      sx={{ alignItems: 'center', mb: 0.5 }}
                    >
                      {errorMessage}
                    </Alert>
                  )}
                  <PaymentCardList
                    currentMethod={currentMethod}
                    onMethodChange={handleChange}
                    latestCardId={latestCardId}
                    onAddressChange={handleStripeAddressChange}
                    paymentMethodId={selectedCard?.id}
                  />
                  <CustomRadioGroup
                    name="payment-method"
                    value={currentMethod}
                    onChange={(e) =>
                      handleChange(e.target.value as PaymentMethodEnum)
                    }
                    options={METHODS}
                    sxLabel={(selected) =>
                      paymentMethodSx(
                        selected === currentMethod,
                        isOptimizedSpace,
                      )
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={5}>
                <NewPaymentReceipt
                  title={'PURCHASE PREVIEW'}
                  data={paymentReceiptData}
                  optimizedSpace={isOptimizedSpace}
                />
                <EnhancedBox sx={{ mt: 2 }} optimized={isOptimizedSpace}>
                  <Stack alignItems="center" sx={{ mb: 2 }}>
                    {isShowCaptcha && (
                      <Captcha
                        forwardRef={captchaRef}
                        onVerify={onCaptchaVerified}
                        onExpire={onCaptchaExpired}
                      />
                    )}
                  </Stack>
                  {currentMethod === PaymentMethodEnum.paypal ? (
                    <PayPalButtons
                      createOrder={onCreatePaypalOrder}
                      onApprove={paypalApproveCallbackDecorator}
                      style={{ color: 'blue' }}
                      fundingSource="paypal"
                      onShippingChange={onPaypalShippingChange}
                      {...captchaCondition}
                    />
                  ) : (
                    <LoadingButton
                      fullWidth
                      loading={loading}
                      type="submit"
                      {...captchaCondition}
                    >
                      PURCHASE
                    </LoadingButton>
                  )}
                </EnhancedBox>
              </Grid>
            </Grid>
          </Box>
        </FormProvider>
      </Box>
    </StyledPaymentModal>
  );
};

export default PaymentModal;
