import React, { useContext } from 'react';
import { Stack, Button } from '@mui/material';

import { useGlobalState, AgoraVideoContext, useGlobalDispatch } from '~utils';
import useAccount from '~hooks/useAccount';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
import { IAgoraRTCClient } from 'agora-rtc-sdk-ng';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { faShuffle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { updateLayoutCloudRecording } from '~api/event';

const ArtistSpotlightViewActions = () => {
  const globalState = useGlobalState();
  const {
    artist: { currentEvent },
    config: {
      localVideoTrack,
      localAudioTrack,
      showShuffleButton = false,
      isArtistLive,
      isFanLive,
    },
    app: { shuffle, stageFanUsername },
  } = globalState as any;

  const dispatch = useGlobalDispatch();
  const { showLoginPopup, showStage } = useDispatchPopup();
  const agoraVideo = useContext(AgoraVideoContext);
  const { connectionState: agoraVideo_connectionState } = agoraVideo || {};
  const { isViewer, type, userId, username } = useAccount();

  const leaveEvent = async () => {
    await (agoraVideo as IAgoraRTCClient).unpublish();
    await (agoraVideo as IAgoraRTCClient).setClientRole('audience');
    localAudioTrack && (await localAudioTrack.close());
    localVideoTrack && (await localVideoTrack.close());

    // Update recording layout to single if this is the fan is on stage
    if (stageFanUsername === username) {
      const agoraVideoAlias: any = agoraVideo;
      const audienceUid: number = agoraVideoAlias?._joinInfo.uid;
      await updateLayoutCloudRecording(currentEvent, audienceUid, false);
      dispatch({
        type: 'app',
        payload: {
          stageFanId: '',
          stageFanUsername: '',
        },
      });
    }

    const payload = {
      localAudioTrack: null,
      localVideoTrack: null,
      isFanLive: false,
    };
    dispatch({
      type: 'config',
      payload,
    });
  };
  const joinLeaveStream = () => {
    if (!userId) {
      showLoginPopup();
      return;
    }
    isFanLive ? leaveEvent() : showStage();
  };

  return (
    <Stack direction="row">
      {isViewer &&
        isArtistLive &&
        agoraVideo_connectionState === 'CONNECTED' && (
          <Stack flex="1" alignItems="start">
            <Button
              variant="text"
              color="warning"
              onClick={joinLeaveStream}
              startIcon={
                isFanLive ? (
                  <IndeterminateCheckBoxOutlinedIcon />
                ) : (
                  <AddBoxOutlinedIcon />
                )
              }
            >
              {isFanLive ? 'LEAVE' : 'JOIN SPOTLIGHT'}
            </Button>
          </Stack>
        )}
      {!type && !userId && (
        <Stack flex="1" alignItems="start">
          <Button
            variant="text"
            color="warning"
            disabled={!isArtistLive}
            onClick={showLoginPopup}
            startIcon={<AddBoxOutlinedIcon />}
          >
            JOIN SPOTLIGHT
          </Button>
        </Stack>
      )}
      {showShuffleButton && (
        <Stack flex="1" alignItems="end">
          <Button
            sx={{ minWidth: 120 }}
            variant="text"
            color="warning"
            onClick={() =>
              dispatch({
                type: 'app',
                payload: { shuffle: !shuffle },
              })
            }
            startIcon={<FontAwesomeIcon icon={faShuffle as IconProp} />}
          >
            {shuffle ? 'SHUFFLING' : 'SHUFFLE'}
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default ArtistSpotlightViewActions;
